<template>
  <div>
    <PageHeader />
    <b-overlay :show="isFetchingBrands">
      <b-card>
        <ValidationObserver
          ref="brandForm"
          v-slot="{ handleSubmit }"
        >
          <form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('brands.name')"
              rules="required"
            >
              <b-form-group>
                <label for="name">
                  {{$t('brands.name')}}
                  <small class="text-size-10 text-muted">
                    {{ $t('fields.updatedAt')}}:
                    {{brand.brand_name.last_updated | datetime}}
                  </small>
                </label>
                <b-form-input
                  id="name"
                  v-model="brandForm.brand_name"
                  :state="errors[0] ? false : null"
                ></b-form-input>
              </b-form-group>
            </ValidationProvider>
            <b-form-group>
              <label for="logo">
                {{$t('brands.logo')}}
                <small class="text-size-10 text-muted">
                  {{ $t('fields.updatedAt')}}:
                  {{ brand.logo.last_updated | datetime}}
                </small>
              </label>
              <b-form-file
                id="logo"
                v-model="brandForm.file"
                accept="image/*"
              >
              </b-form-file>
              <b-form-text>
                <b-link
                  v-if="brandForm.file"
                  class="text-danger"
                  @click="brandForm.file = null"
                >
                  {{ $t('buttons.remove')}}
                </b-link>
              </b-form-text>
            </b-form-group>
            <b-row>
              <b-col>
                <b-img
                  v-if="brand.logo.value && brand.logo.value !== 'null'"
                  :src="brand.logo.value"
                  height="100"
                ></b-img>
                <img
                  v-else
                  src="@assets/images/img-placeholder.jpeg"
                  alt
                  height="100"
                />
              </b-col>
            </b-row>
            <div class="text-right">
              <b-overlay
                :show="isCreating"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="primary"
                >
                  {{ $t('buttons.edit')}}
                </b-button>
              </b-overlay>
            </div>
          </form>
        </ValidationObserver>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'Brand Settings',
  },
  data() {
    return {
      file: null,
      brandForm: {
        brand_name: '',
        file: null,
      },
    }
  },
  computed: {
    ...mapState({
      isFetchingBrands: (state) => state.brand.isFetchingBrands,
      isCreating: (state) => state.brand.isCreatingBrand,
    }),
    ...mapGetters(['brand']),
  },
  watch: {
    brand(val) {
      if (val) {
        this.setData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchBrands', 'createBrand']),
    fetchData() {
      this.fetchBrands()
    },
    setData() {
      if (this.brand) {
        const data = JSON.parse(JSON.stringify(this.brand))
        this.brandForm.brand_name = data.brand_name.value
      }
    },
    onSubmit() {
      this.brandForm.logo = this.file
      this.createBrand(this.brandForm)
    },
  },
}
</script>
